@import '../../../variables';

footer {
    text-align: center;
    font-family: 'Caveat', cursive;
    font-size: 1rem;
    padding: 20px;

    .copyright {
        color: #cccccc;
    }
}