header {
    display: flex;
    justify-content: space-between;
    padding: 40px;
    box-sizing: border-box;

    &.fixed {
        position: fixed;
        top: 0;
        width: 100%;
    }

    .logo {
        font-family: 'Caveat', cursive;
        font-size: 1.5rem;
    }
}