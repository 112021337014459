@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;600&display=swap');
@import './variables';

html {
	height: 100%;
}

body {
	height: 100%;
	margin: 0;
	font-family: 'Caveat', cursive;
	font-size: 2rem;
	color: $textColor;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
	color: $turquoiseDark;
	text-decoration: none;	
}

#root {
	height: 100%;
}

.app {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
}

main {
	flex: 1;
	padding: 0 40px;

	&.full-screen {
		padding: 0;
	}
}

.main-part {
	padding: 40px 0;
}

section {
	text-align: center;
}

.controls {
	text-align: center;
	display: flex;
    flex-direction: column;
	margin: 40px auto;

	form {
		display: flex;
    	flex-direction: column;
	}
}

h1,
h2,
h3,
h4,
h5 {
	font-family: 'Caveat', cursive;
	color: $turquoiseDark;
}

h1 {
	text-align: center;
	margin: 30px auto;
	font-size: 2.5rem;
	font-weight: normal;
	line-height: 2.5rem;
}

h2 {
	text-align: center;
	margin: 30px auto;
	font-size: 1.75rem;
	font-weight: normal;
	line-height: 1.5rem;
}


.turquoise {
	color: $turquoise;
}

.turquoiseDark {
	// color: $turquoiseDark;
}

.pink {
	color: $pink;
}

.image {
	text-align: center;

	svg {
		width: 100%;
		max-width: 400px;
		height: auto;
		border-radius: 10px;
	}

	img {
		width: 100%;
		max-width: 400px;
		height: auto;
	}
}

.input {
	box-sizing: border-box;
	min-width: 260px;
	height: 50px;
	margin-bottom: 20px;
	padding: 0 20px;
	font-family: 'Caveat', cursive;
	font-size: 1.5rem;
	border: 1px solid $turquoise;
	border-radius: 6px;
	outline: none;
}

.inputs-group {
	display: flex;
	justify-content: space-between;

	.input {
		text-align: center;
		width: 75px;
		min-width: 0px;

		&.blue {
			border-color: blue;
		}

		&.orange {
			border-color: orange;
		}

		&.black {
			border-color: black;
		}
	}
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #64BDC7;
	opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
	color: #64BDC7;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
	color: #64BDC7;
  }

.button {
	display: inline-block;
	box-sizing: border-box;
	min-width: 260px;
	height: 50px;
	padding: 0 40px;
	font-family: 'Caveat', cursive;
	font-size: 1.5rem;
	font-weight: bold;
	line-height: 50px;
	color: white;
	background-color: $turquoise;
	border: none;
	border-radius: 6px;
	box-shadow: rgb(0 0 0 / 20%) 0px 5px 10px;
	cursor: pointer;
	appearance: none;

	&.secondary {
		color: #333333;
		background-color: #e5e5e5;
	}
}

.error {
	text-align: center;
	font-size: 1.5rem;
	color: #c12525;
}