@import '../../../variables';

.nav-wrap {
    flex: 1;
    margin: auto;

    .nav {
        text-align: center;

        .nav-item {
            margin: 20px;
            font-family: 'Caveat', cursive;
            font-size: 1.1rem;
        }
    }
}